<template>
  <footer class="footer">
    <b-container>
      <b-row class="footer-one">
        <b-col cols="12" md="6" sm="6" lg="3" class="menu-col my-lg-3 my-2">
          <div class="logo">
            <img src="~@/assets/images/logo/dark.png" alt="" />
          </div>
          <div class="social-footer">
            <a
              href="https://bit.ly/3kUGV2p"
              target="_blank"
              v-b-tooltip.hover
              title="Fanpage Facebook"
            >
              <img src="~@/assets/images/icons/facebook.png" alt="" />
            </a>
            <a
              href="https://bit.ly/3FtGhku"
              target="_blank"
              v-b-tooltip.hover
              title="Telegram Group Community"
            >
              <img src="~@/assets/images/icons/telegram.png" alt="" />
            </a>
            <a
              href="https://bit.ly/3oSQfVE"
              target="_blank"
              v-b-tooltip.hover
              title="Telegram Channel"
            >
              <img src="~@/assets/images/icons/telegram.png" alt="" />
            </a>
            <a
              href="https://bit.ly/3CMfzSw"
              target="_blank"
              v-b-tooltip.hover
              title="Telegram Group VN"
            >
              <img src="~@/assets/images/icons/telegram.png" alt="" />
            </a>
            <a href="https://bit.ly/30ECXnX" target="_blank" v-b-tooltip.hover title="Twitter Page">
              <img src="~@/assets/images/icons/Twitter.png" alt="" />
            </a>
            <a
              href="https://bit.ly/30KjcL5"
              target="_blank"
              v-b-tooltip.hover
              title="Youtube Channel"
            >
              <img src="~@/assets/images/icons/youtube.png" alt="" />
            </a>
          </div>
        </b-col>
        <b-col cols="12" md="6" sm="6" lg="3" class="my-lg-3 my-2">
          <ul class="menu-footer">
            <li>
              <a href="/home"> ESB FX </a>
            </li>
            <li>
              <a href="/why-esb-fx"> Why should you choose ESB FX? </a>
            </li>
            <li>
              <a href="/trading"> Trade now </a>
            </li>
            <li>
              <a href="/trading-platforms"> Platforms and tools </a>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" md="6" sm="6" lg="3" class="my-lg-3 my-2">
          <ul class="menu-footer">
            <li>
              <a href=""> Learn how to trade forex </a>
            </li>
            <li>
              <a href=""> Learn how to trade CFDs</a>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" md="6" sm="6" lg="3" class="my-lg-3 my-2">
          <ul class="menu-footer">
            <li>
              <a href=""> Contact </a>
            </li>
            <li>
              <a href="mailto:Support@esbfx.com"> Support@esbfx.com </a>
            </li>
            <li>
              <a href="tel:+1786 628 1209"> +1786 628 1209 </a>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="footer-three">
        <b-col cols="12">
          <ul class="menu-policy">
            <li>
              <a href="#"> Legal documents </a>
            </li>
            <li>
              <a href="https://policy.esbfx.com/" target="_blank">Privacy policy</a>
            </li>
            <li>
              <a href="#">Website terms and conditions</a>
            </li>
            <li>
              <a href="#">Cookie policy</a>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" class="mx-auto">
          <p class="warning-title">
            <img src="~@/assets/images/icons/warning.png" alt="" />
            <span>Risk warning</span>
            <img class="two" src="~@/assets/images/icons/warning.png" alt="" />
          </p>

          <p>
            The risks of loss from investing in CFDs can be substantial and the value of your
            investments may fluctuate. CFDs are complex instruments and come with a high risk of
            losing money rapidly due to leverage. You should consider whether you understand how
            this product works, and whether you can afford to take the high risk of losing your
            money.
          </p>
          <p>
            ESB FX provides an execution-only service. The information in this site does not contain
            (and should not be construed as containing) investment advice or an investment
            recommendation, or an offer of or solicitation for transaction in any financial
            instrument. ESB FX accepts no responsibility for any use that may be made of these
            comments and for any consequences that result.
          </p>
          <p>
            You don't own or have rights in the underlying assets. Past performance is no indication
            of future performance and tax laws are subject to change. The information on this
            website is general in nature and doesn't take into account your or your client's
            personal objectives, financial circumstances, or needs. Please ensure you fully
            understand the risks before you make any trading decisions. We encourage you to seek
            independent advice.
          </p>
        </b-col>
        <b-col cols="12" class="mt-4">
          <p>
            ESB FX is under of the ENTERPRISE SERVICE MANAGEMENT PTE. LTD (202203414Z) 10 Anson Road
            # 13 - 09 International Plaza Singapore
          </p>
          <p>
            The information on this site and the products and services offered are not intended for
            distribution to any person in any country or jurisdiction where such distribution or use
            would be contrary to local law or regulation.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
footer {
  background-image: url(~@/assets/images/background/bgfooter.jpg);
  background-size: cover;
  min-height: 275px;
  -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
  box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
  padding: 0rem 80px 0rem;
  .footer-one {
    .menu-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .logo {
        img {
          width: 100%;
          max-width: 180px;
          height: auto;
        }
      }
      .social-footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        margin-bottom: 0;
        a {
          width: 30px;
          height: 30px;
          margin-right: 15px;
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .menu-footer {
      padding: 0 22px;
      margin-bottom: 0;
      li {
        padding: 10px 0;
        &:first-child {
          margin-bottom: 10px;
          a {
            font-size: 17px;
            font-weight: 700;
          }
        }
        a {
          color: #fff;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
  .footer-two {
    padding: 40px 0;
    border-top: 2px solid rgba($color: #ffffff, $alpha: 0.4);
    color: #fff;

    @media (max-width: 1440px) {
      padding: 20px 0 15px;
    }
  }
  .footer-three {
    padding: 10px 0;
    border-top: 2px solid rgba($color: #ffffff, $alpha: 0.4);
    color: #fff;
    .warning-title {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 30px;
        margin-right: 5px;
      }
      img.two {
        width: 30px;
        margin-left: 5px;
      }
      span {
        color: #ffc107;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .menu-policy {
      margin-bottom: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        padding-right: 40px;
        margin-bottom: 10px;

        a {
          color: #009750;
          font-size: 17px;
          font-weight: 600;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .footer-one {
      .menu-footer {
        padding: 0 22px;
        margin-bottom: 0;
        li {
          padding: 0 0 10px;
          &:first-child {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 2rem 15px;
  }
}
</style>
