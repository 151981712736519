const axios = require('./axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    error: '',
    success: '',
    notifileList: [],
  }),

  getters: {
    Error: (state) => state.error,
    Success: (state) => state.success,
    NotifileList: (state) => state.notifileList,
  },

  actions: {
    req_getNotificationImage({ commit }) {
      axios.get('api/v1/notification-image').then((res) => {
        if (res) {
          commit('SET_NOTIFICATION_IMAGE', res);
        }
      });
    },
  },

  mutations: {
    SUCCESS_MESSAGE(state, message) {
      state.success = message;
    },
    ERROR_MESSAGE(state, message) {
      state.error = message;
    },
    ERROR_500(state, message) {
      state.status = message;
    },
    SET_ACCOUNT_TYPE(state, data) {
      state.type_account = data;
    },
    SET_NOTIFICATION_IMAGE(state, data) {
      state.notifileList = data.landing;
    },
  },
};
