import axios from 'axios';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import store from '..';

Vue.use(VueCookies);
const token = window.$cookies.get('eggsbook_user');
if (token != null && token.key != null) {
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common.Authorization = `Bearer ${token.key}`;
}
const instance = axios.create({
  baseURL: process.env.VUE_APP_APIBETA,
  ContentType: 'application/json',
  Accept: 'application/json',
});
function checkCodeStatus(code) {
  if (code >= 500) {
    return 500;
  }
  if (code >= 400) {
    return 400;
  }
  return false;
}
instance.interceptors.request.use(
  (config) => {
    store.commit('onLoad');
    return config;
  },
  (error) => Promise.reject(error),
);
instance.interceptors.response.use(
  (res) => {
    if (res.data.status) {
      store.commit('outLoad');
      if (
        res.data.message
        && res.data.message.length > 1
        && res.data.message != null
        && res.data.message !== ''
      ) {
        store.commit('core/SUCCESS_MESSAGE', res.data.message, { root: true });
      }
      return res.data.data;
    }
    store.commit('core/ERROR_MESSAGE', res.data.message, { root: true });
    return false;
  },
  (error) => {
    store.commit('outLoad');
    if (error.response) {
      if (error.response.status === 401) {
        store.commit('auth/LOGOUT_SUCCESS');
        window.location.href = `${window.location.origin}/secure`;
        return;
      }
      if (checkCodeStatus(error.response.status) === 500) {
        store.commit('core/ERROR_500', 500, { root: true });
        return;
      }

      if (checkCodeStatus(error.response.status) === 400) {
        store.commit('core/ERROR_MESSAGE', 'Something went wrong. Please try again', {
          root: true,
        });
      }
    }
  },
);

export default instance;
