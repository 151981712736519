<template>
  <div class="app-eggbookfx">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch('core/req_getNotificationImage');
  },
};
</script>
