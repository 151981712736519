<template>
  <header id="header">
    <b-container>
      <b-row>
        <b-col cols="12" class="position-relative">
          <b-navbar toggleable="lg" type="light" variant="light" class="nav-main">
            <b-navbar-brand href="#" class="logo-section">
              <div class="logo-img">
                <img src="~@/assets/images/logo/onlyicon.png" alt="" />
              </div>
              <div class="text-logo">
                <span class="eggsbook">ESB</span>
                <span class="effect"> Fx</span>
              </div>
            </b-navbar-brand>
            <div class="group-mobile align-items-center">
              <a href="https://system.esbfx.com/secure/login" target="_blank" class="mr-4">
                <b-button variant="success"> Login </b-button>
              </a>
              <b-navbar-toggle target="nav-collapse">
                <template #default="{ expanded }">
                  <a class="menu-icon" :class="{ active: expanded }">
                    <span>Menu</span>
                  </a>
                </template>
              </b-navbar-toggle>
            </div>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                <b-nav-item href="/home">
                  <span>Home</span>
                </b-nav-item>
                <b-nav-item-dropdown text="ESB FX" left class="animated animate__zoomIn">
                  <b-dropdown-item href="/about">Who is ESB FX ?</b-dropdown-item>
                  <b-dropdown-item href="/why-esb-fx">
                    Why should you choose ESB FX ?
                  </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item href="/trading">
                  <span>Trade now</span>
                </b-nav-item>
                <b-nav-item href="/trading-platforms">
                  <span>Platforms and tools</span>
                </b-nav-item>

                <b-nav-item-dropdown text="Trading knowledge" left class="animated animate__zoomIn">
                  <b-dropdown-item href="#">Tools are available</b-dropdown-item>
                  <b-dropdown-item href="#">Currency Index CFDs</b-dropdown-item>
                  <b-dropdown-item href="#">Trading hours</b-dropdown-item>
                  <b-dropdown-item href="#">Deposit and withdraw</b-dropdown-item>
                  <b-dropdown-item href="#">Starting</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item href="/support">
                  <span> Help & Support</span>
                </b-nav-item>

                <b-nav-item  href="https://system.esbfx.com/secure/login" target="_blank" class="button-pc">
                  <b-button variant="success"> Login </b-button>
                </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
export default {
  components: {},
  methods: {
    ChangeMenu() {
      this.$emit('show-menu', true);
    },
    onLogout() {
      this.$emit('logout', true);
    },
  },
};
</script>

<style lang="scss">
header#header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  height: 100px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  .nav-main {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    min-height: 80px;
    background: #fff;
    box-shadow: 2px 2px 6px 1px #0000002b;
    &:after {
      content: '';
      position: absolute;
      width: 95%;
      height: 80px;
      margin: auto;
      left: 0px;
      right: 0px;
      z-index: -1;
      top: 10px;
      background: #fff;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.13);
      opacity: 0.62;
    }
    .logo-section {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .logo-img {
        img {
          height: 1.8rem;
          width: auto;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
        }
      }
      .text-logo {
        margin-left: 5px;
        span {
          &.effect {
            background: #009750;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            line-height: 120%;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
          }
          &.eggsbook {
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            color: #5d5d5d;
            line-height: 120%;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
          }
        }
        @media (max-width: 576px) {
          display: none;
        }
      }
    }
    .group-mobile {
      display: flex;
      @media (min-width: 992px) {
        display: none;
      }
      a {
        button {
          border-radius: 50px;
          min-width: 100px;
        }
      }
    }
    #nav-collapse {
      .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @media (max-width: 991px) {
          justify-content: flex-start;
          padding: 0px 20px;
          &.button-pc {
            display: none;
          }
        }
        .nav-link {
          border: 0;
          box-shadow: none !important;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 15px;
            color: #333;
            font-weight: 600;
            text-align: center;
          }
        }

        button {
          border-radius: 25px;
          min-width: 120px;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        }
      }
      .dropdown-menu {
        min-width: 100%;
        padding: 15px 0;
        border-radius: 5px;
        border: 0;
        background-color: #fff;
        box-shadow: 2px 2px 6px 1px #0000002b;
        .dropdown-item {
          padding: 10px 15px;
          min-width: 250px;
          font-size: 15px;
          color: #333;
        }
        .dropdown-item:hover,
        .dropdown-item:focus,
        .dropdown-item.active,
        .dropdown-item:active {
          background: #e9ecef;
          border-left: 3px solid #16181b;
          font-weight: 600;
        }
      }
    }
    @media (max-width: 991px) {
      .button-pc {
        display: none;
      }
      .navbar-toggler {
        position: relative;
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: 0;
        .menu-icon {
          cursor: pointer;
          display: block;
          position: relative;
          top: 0;
          width: 32px;
          height: 40px;
          text-indent: -9999em;
          z-index: 99;
          right: 0;
          span {
            -moz-transition: all 0.4s;
            -o-transition: all 0.4s;
            -webkit-transition: all 0.4s;
            transition: all 0.4s;
            background-color: #3b566e;
            display: block;
            position: absolute;
            width: 30px;
            height: 2px;
            left: 0;
            top: 16px;
            &::after,
            &::before {
              content: '';
              -moz-transition: all 0.4s;
              -o-transition: all 0.4s;
              -webkit-transition: all 0.4s;
              transition: all 0.4s;
              background-color: #3b566e;
              display: block;
              position: absolute;
              width: 30px;
              height: 2px;
              left: 0;
              width: 75%;
            }
            &::before {
              -moz-transform-origin: 33% 100%;
              -ms-transform-origin: 33% 100%;
              -webkit-transform-origin: 33% 100%;
              transform-origin: 33% 100%;
              top: -10px;
              z-index: 10;
            }
            &::after {
              -moz-transform-origin: 33% 0;
              -ms-transform-origin: 33% 0;
              -webkit-transform-origin: 33% 0;
              transform-origin: 33% 0;
              top: 10px;
            }
          }
          &.active {
            span {
              background-color: transparent;
              width: 100%;
              &::before,
              &::after {
                background-color: transparent;
                width: 100%;
              }
              &::before {
                -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
                -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
                -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
                transform: translateY(6px) translateX(1px) rotate(45deg);
                background-color: #3b566e;
              }
              &::after {
                -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
                -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
                -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
                transform: translateY(-6px) translateX(1px) rotate(-45deg);
                background-color: #3b566e;
              }
            }
          }
        }
      }
    }
  }
}
</style>
