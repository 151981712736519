<template>
  <div class="eggsbook-fx">
    <Header />
    <div class="content-page">
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Header from './Header.vue';
import Footer from './Footer.vue';

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showSidebar: true,
      page: '',
      showContent: true,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
    }),
    getNameUser() {
      const user = this.UserInfo;
      if (user.name) {
        return `${user.surname} ${user.name}`;
      }
      return user.email.split('@')[0];
    },
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
      if (window.innerWidth <= 1200) {
        this.showSidebar = false;
      } else {
        this.showSidebar = true;
      }
    },
    onLogout() {
      this.$store.commit('auth/LOGOUT_SUCCESS');
    },
  },
  async mounted() {
    if (window.innerWidth <= 1200) {
      this.showSidebar = false;
    } else {
      this.showSidebar = true;
    }
    this.page = this.$route.meta.page;
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    $route(to, from) {
      this.page = to.meta.page;
      if (to.name !== from.name && window.innerWidth <= 767) {
        this.showSidebar = false;
      }
    },
    showSidebar: {
      handler() {
        this.showContent = false;

        this.$nextTick(() => {
          this.showContent = true;
        });
      },
    },
  },
};
</script>

<style lang="scss"></style>
