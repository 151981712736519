import Vue from 'vue';
import VueRouter from 'vue-router';

import Master from '../layouts/Master.vue';

function LoadingViews(view) {
  return () => import(`@/views/${view}.vue`);
}
function LoadingViewsFolder(folder, view) {
  return () => import(`@/views/${folder}/${view}.vue`);
}
function LoadingComponents(folder, view) {
  return () => import(`@/components/${folder}/${view}.vue`);
}
Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '',
    component: Master,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: LoadingViewsFolder('home', 'Home'),
      },
      {
        path: 'why-esb-fx',
        name: 'EggsbookFx-wcb',
        component: LoadingViewsFolder('eggsbookfx', 'Index'),
      },
      {
        path: 'about',
        name: 'EggsbookFx-about',
        component: LoadingViewsFolder('eggsbookfx', 'About'),
      },
      {
        path: 'trading',
        name: 'TradeNow',
        component: LoadingViewsFolder('tradenow', 'Index'),
      },
      {
        path: 'trading-platforms',
        name: 'TradingPlatforms',
        component: LoadingViewsFolder('tool', 'Index'),
      },
      {
        path: 'support',
        name: 'Support',
        component: LoadingViewsFolder('help', 'FAQ'),
      },
      {
        path: 'contact',
        name: 'Contact',
        component: LoadingViewsFolder('contact', 'contact'),
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    component: LoadingViewsFolder('errors', '404'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 1 };
    }
  },
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-children',
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
